<template>
	<div>
		<Header :title="isEdit ? '修改攻略' : '新增攻略'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 5, xxl: 4}" :wrapperCol="{span: 17, xxl: 18 }" @finish="onSubmit">

				<a-form-item label="攻略标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.title" placeholder="请输入攻略标题"></a-input>
				</a-form-item>

				<a-form-item label="封面图片" name="img" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
					<a-upload v-model:file-list="fileList" list-type="picture-card" action="/admin/ajaxUpload.do"
						accept="image/*" @change="onUploadChange">
						<div v-if="fileList.length < 1">
							<Icon icon="PlusOutlined"></Icon>
							<div style="margin-top: 8px">Upload</div>
						</div>
					</a-upload>
				</a-form-item>
				
				<a-form-item label="展示时间" name="time" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-range-picker v-model:value="modelRef.time" showTime></a-range-picker>
				</a-form-item>
				
				<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
					<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
				</a-form-item>
				
				<a-form-item label="是否热门攻略" name="isHot" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group v-model:value="modelRef.isHot">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="攻略图文">
					<div style="position: relative;z-index: 2;">
						<Editor v-model:value="modelRef.content" :configSetting="{ disabled: isSee }"></Editor>
					</div>
				</a-form-item>

				<a-form-item label="关联演出" name="performIds">
					<a-form-item-rest>
						<selectPerform v-model:value="modelRef.performIds" :selectItems="list" @change="(data) => {list = data}"></selectPerform>
						<div>
							<a-table :columns="columns" :dataSource="list" :pagination="false" rowKey="id" :scroll="{x: 1000}">
								<template #bodyCell="{column, record, index}">
									<template v-if="column.key === 'index'">
										{{ index +1 }}
									</template>
									<template v-if="column.key === 'img'">
										<a-image :src="record.posterUrl" style="width: 60px;"></a-image>
									</template>
									<template v-if="column.key === 'isDisabled'">
										{{ record.isDisabled ? '已禁用' : '已启用' }}
									</template>
									<template v-if="column.key === 'action'">
										<a-dropdown :trigger="['click', 'hover']">
											<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
											</a-button>
											<template #overlay>
												<a-menu>
													<div v-if="index !== 0" @click="onTop(record,index)">
														<a-menu-item>
															上移
														</a-menu-item>
													</div>
													<div v-if="index !== list.length - 1" @click="onBottom(record,index)">
														<a-menu-item>
															下移
														</a-menu-item>
													</div>
													<div @click="onDelete(record,index)">
														<a-menu-item>
															删除
														</a-menu-item>
													</div>
												</a-menu>
											</template>
										</a-dropdown>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item-rest>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import selectPerform from '@/components/select/selectPerform/index.vue';
	import { getPerformStrategyDetail, savePerformStrategy, updatePerformStrategy } from '@/service/modules/perform.js';
	export default {
		components: {
			Header,
			Icon,
			Editor,
			selectPerform
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				fileList: [],
				loading: false,
				modelRef: {
					isHot: 0,
					isDisabled: 0,
					performIds: []
				},
				columns: [{
					title: '序号',
					key: 'index',
					width: 80
				}, {
					title: '演出名称',
					dataIndex: 'name'
				}, {
					title: '演出海报',
					key: 'img'
				}, {
					title: '演出分类',
					dataIndex: 'classifyName'
				},  {
					title: '演出标签',
					dataIndex: 'tagName'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				list: []
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.performIds = postData.performIds.join(',');
				postData.displayStartTime = this.moment(postData.time[0]).unix();
				postData.displayEndTime = this.moment(postData.time[1]).unix();
				delete postData.time;
				let ret;
				this.loading = true;
				if (this.isEdit && this.id) {
					ret = await updatePerformStrategy(postData);
				} else {
					ret = await savePerformStrategy(postData)
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！');
					this.onBack(true);
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformStrategyDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.fileList = [{
						uid: 0,
						name: ret.data.img,
						url: ret.data.img
					}];
					if (ret.data.displayStartTime) {
						ret.data.time = [this.moment(ret.data.displayStartTime * 1000), this.moment(ret.data.displayEndTime * 1000)];
					} else {
						ret.data.time = [];
					}
					if (ret.data.performIds) {
						ret.data.performIds = ret.data.performIds.split(',').map(item => Number(item));
					} else {
						ret.data.performIds = [];
					}
					this.list = ret.data.performInfoList || [];
					this.modelRef = ret.data;
				}
			},
			onTop(item, index) {
				let newItem = this.list.splice(index, 1);
				this.list.splice(index - 1, 0, newItem[0]);
				this.modelRef.performIds = this.list.map(item => item.id);
			},
			onBottom(item, index) {
				let newItem = this.list.splice(index, 1);
				this.list.splice(index + 1, 0, newItem[0]);
				this.modelRef.performIds = this.list.map(item => item.id);
			},
			onDelete(item, index) {
				let i = this.modelRef.performIds.indexOf(item.id);
				this.modelRef.performIds.splice(i, 1);
				this.list.splice(index, 1);
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.img = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			}
		}
	}
</script>

<style scoped>

</style>