<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="攻略名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入攻略名称"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" name="isDisabled" label="状态">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="isHot" label="是否热门">
						<a-select v-model:value="formState.isHot" placeholder="请选择是否热门" allow-clear style="width: 180px;">
							<a-select-option :value="0">否</a-select-option>
							<a-select-option :value="1">是</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="展示时间" class="ui-form__item">
						<a-range-picker v-model:value="time" showTime></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_special_introduction_add']" type="primary" @click="onAdd">新增攻略</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<cTable ref="cTable" :isPage="true" :columns="columns" :isRequest="true" :requestFun="getPerformStrategyList" :searchData="searchData">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'cover'">
							<a-image :width="50" :src="record.img" />
						</template>
						<template v-if="column.key === 'showTime'">
							<div>
								<div>{{ transDateTime(record.displayStartTime) }}</div>
								至
								<div>{{ transDateTime(record.displayEndTime) }}</div>
							</div>
						</template>
						<template v-if="column.key === 'isHot'">
							{{ record.isHot ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_special_introduction_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_introduction_disabled']" v-if="record.isDisabled" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_introduction_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_introduction_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import { getPerformStrategyList, disabledPerformStrategy, deletePerformStrategy } from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			cTable,
			temp
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				searchData: {},
				formState: {},
				time: [],
				columns: [{
					title: '攻略名称',
					dataIndex: 'title',
				}, {
					title: '攻略封面',
					key: 'cover',
				}, {
					title: '优先级',
					dataIndex: 'sort',
					width: 100
				}, {
					title: '展示时间',
					key: 'showTime'
				}, {
					title: '是否热门',
					key: 'isHot'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getPerformStrategyList: getPerformStrategyList,
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.displayStartTime = this.time[0].unix();
					this.searchData.displayEndTime = this.time[1].unix();
				}
				this.getData();
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }该数据吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await disabledPerformStrategy({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '启用' : '禁用' }成功!`);
							this.getData();
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: `确定删除该数据吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await deletePerformStrategy({
							id: item.id,
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`删除成功!`);
							this.getData(true);
						}
					}
				})
			},
			onAdd() {
				this.id =0;
				this.isEdit = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.showModal = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>